import Dialog from '@/components/Dialog'
import { Box } from '@mui/material'
import React from 'react'
import { palette } from '../../../../styles/theme'
import RequestContactForm from '../RequestContactForm'

export interface RequestContactDialogProps {
  open: boolean
  source: string
  email?: string
  onClickClose: React.Dispatch<boolean>
}

const RequestContactDialog: React.FC<
  React.PropsWithChildren<RequestContactDialogProps>
> = ({ open, onClickClose, source, email }) => {
  return (
    <>
      <Dialog
        open={open}
        title="Informações adicionais"
        onClickClose={() => onClickClose(false)}
        subtitle="Algumas informações para que nosso time entre em contato com você."
      >
        <Box
          sx={{
            textAlign: 'left',
            svg: {
              fill: palette.secondary,
            },
          }}
        >
          <RequestContactForm
            email={email}
            source={source}
            postSubmit={() => onClickClose(false)}
          />
        </Box>
      </Dialog>
    </>
  )
}
export default RequestContactDialog
